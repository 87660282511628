.title {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 15px;
    font-size: 35px;
    color: #006089;
    text-align: left;
}

.BHT-title {
    margin-bottom: 0;
    margin-left: 30px;
    text-decoration: none !important;
    text-transform: uppercase;
    font-style: italic;
    letter-spacing: 1.78px;
    color: #f4c100 !important;
    opacity: 1;
    font-size: 15px;
}

.BHT-title>span {
    margin-left: 15px;
}

.formWrapper {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
    height: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 15px 22px #00000029;
    border: 0.5px solid #AAAAAA;
    border-radius: 10px;
    opacity: 1;
}

.bhtform {
    padding: 20px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.logo {
    left: 0;
}

.heading {
    font-size: x-large;
    padding-left: 20px;
    color: #1B8202;
    font-weight: bold;
}

.logo>img {
    width: 100px;
    height: 100px;
}

.para {
    font-weight: bold;
    color: #F6821F;
}

.content {
    border: 2px dotted lightgrey;
    border-radius: 5px;
    padding: 10px;
    font-weight: 500;
}

.content p {
    margin-bottom: 0;
}

.sideHeading {
    margin-top: 50px;
    font-weight: bold;
    color: #F6821F;
}

.paraInput {
    font-weight: 500;
}

.paraInput2 {
    margin-top: 20px;
    font-weight: 500;
}

.declarationTitle {
    font-size: large;
    padding-left: 30px;
    color: #1B8202;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer {
    background-color: #F0F0F0;
    opacity: 1;
    height: auto;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
}

.footerParaWrapper{
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
}

.footerPara {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    /* gap: 100px */
}

.footerlogo {
    left: 0;
}

.footerDetails {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 20px;
}

.footerDetails>p {
    width: 90%;
    margin-bottom: 0;
    word-break: break-all;
}

.footerlogo>img {
    width: 30px;
    height: 30px;
}

.success-modal {
    border-radius: 20px !important;
    width: 420px;
    height: 270px;
}

.modal-header {
    border-bottom: 0 none !important;
}

.resetpassword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.successchangepassword-icon {
    width: 120px;
    height: 100px;
}

.success-text {
    color: #006089;
    font-weight: bold;
    font-size: 16px;
}

.success-modal-desc {
    margin-top: 8px;
    color: #006089;
    opacity: 1;
    font-size: 14px !important;
}

.submit {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 80px;
}

.submit-btn {
    cursor: pointer;
    width: 142px;
    height: 45px;
    background: #f4c100 0% 0% no-repeat padding-box;
    border: 2px solid #cca202;
    border-radius: 11px;
    opacity: 1;
    letter-spacing: 0px;
    color: #ffffff;
}
.button-container {
    display: flex;
    justify-content: space-between;
    gap: 60px;
    margin-top: 20px; /* Adjust this value to control the vertical gap */
  }
.confirm-btn {
    cursor: pointer;
    width: 60px;
    height: 40px;
    background: #f4c100 0% 0% no-repeat padding-box;
    border: 2px solid #cca202;
    border-radius: 11px;
    opacity: 1;
    letter-spacing: 0px;
    color: #ffffff;
}

.btngroup {
    display: flex;
    gap: 15px;
}

.btngroup>button {
    border: 1px solid;
    width: 80px;
}

.formInput {
    width: 100%;
    height: 35px;
    padding: 10px;
    outline:none;
}

.formInputNoAsterik{
    font-weight: 600;

}

.formPrintNameInput{
    width: 50%;
    outline: 0;

}



.formGroup {
    display: flex;
    gap: 20px;
}

.inputWrapper {
    flex: 1;
    margin-top: 30px;
}



.fgInput {
    font-Weight: 600 !important;

}

.fgInput::after {
    content: "*" !important;
    font-size: 16px;
    color: red !important;
}

.fgInputSignature{
    font-Weight: 600 !important;
}


.customDatePicker {
    height: 35px;
    padding: 10px;
}

/* card styles */

.cardWrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;

}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    background-color: rgb(238, 243, 247);
    border-radius: 10px;
    height: 300px;
    width: 350px;
}

.card img {
    height: 80px;
    width: 80px;
}

.card h6 {
    color: #006089;
    text-decoration: underline;

}

.card p {
    color: #006089;
    text-align: center;
}

.cardFooter {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.cardFooter>p {
    margin-bottom: 0;
    color: #006089;
}

.signCanvasWrapper{
    border: 1px solid black;
    height: 200px;
    margin-bottom: 20px;


}



/* monthly form classes starts here */

.monthly-form_title{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  font-size: 20px;
    font-weight: bold;
      color: #F6821F;
    

}
.bank-details {
margin-left: 200px;
    font-style: italic;
    font-size: 14px;
     
}
.bank-details strong{
    margin:  0 10px;
}

.bankDetailsWrapper{
    margin-top: 20px;
}

.bankDetailsHeading{
    width: 200px;
    font-weight: bold;
    text-decoration: underline;
    margin-right: 40px;

}
.bankDesc{
    display: flex;
}


.monthly-form_title p{
    margin-bottom: 0;
}
.monthly-form_title span{
    font-size: 14px;

}
.inputFamilyMembersFormGroup{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}
.inputFamilyMembersFormGroup > div > p{
    white-space: "";
    margin-bottom: 0;

}
.inputFamilyMembersWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;   
    flex: 1;
   
}
.fieldOfExpertise{
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.expertiseSec{
    width: 100%;
    flex: 1;
}
.radioGroupWrapper{
    display: flex;
    align-items: center;
    margin: 30px 0;

}
.radioGroupWrapper p{
    margin-bottom: 0;
    margin-right: 20px;
}
.formInputActivities{
    min-width: 50%;
    outline: 0;
}
.otherMembersLbl{
    margin-bottom: 10px;
    flex: 1;
}
.otherMembersLbl p{
    margin-bottom: 0;
}

.otherMembers{
display: flex;
flex: 1;
align-items: center;
}

.childrenSec{
    display: flex;
    gap: 10px;

    align-items: center;

}
.childrenSec input{
    width: 20%;
}
.lblname{
    margin:0 10px;
}

.giftAidTitle{
    font-size: 16px;
    color: #1B8202;
    font-weight: bold;
    font-style: italic;
    text-align: center;
}
.giftAidDesc{
    text-align: center;
    color: #F6821F;
    font-weight: bold;
    /* font-size: 10px; */
}
.consent-sec{
    margin-top: 50px;
    
}
.consent-sec p{
    text-align: center;
}
.consentForm{
text-align: center;
color: #F6821F;
font-style: italic;
}
.acceptanceSec{
    margin: 30px 0;
    text-align: justify;   
    word-spacing: 0;
   
}
.acceptanceSec span{
    margin-right: 20px;
}
.radioGroup{
    display: flex;
    margin-left: 20px;
    align-items: center;
}
.radioLbl{
    margin-left: 10px;
}

.donationAmtInput{
    width: 15%;
    margin: 0 10px;
}

/* data protection styles start*/

.memberFormInput {
    width: 100%;
    height: 35px;
    padding: 10px
}

.dateFormInput {
    width: 150%; /* You can use a percentage value for width */
    max-width: 100%;
    height: 35px;
    padding: 10px;
}
/* data protection styles end*/


/* CSS for mobile */
@media only screen and (max-width: 767px) {

    /* Mobile styles go here */
    .monthly-form_title{
       flex-direction: column;   
       gap: 0;    
    
    }
    .formWrapper {
        width: 90%;
    }
   .inputWrapper{
    margin-top: 0;
   }

    .bhtform {
        padding: 20px;

    }

    .header {
        height: 100px;
    }

    .inputWrapper {
        flex-direction: column;
        margin-top: 15px;
    }

    .formGroup {
        flex-direction: column;
    }

    .heading {
        font-size: medium;
    }

    .logo>img {
        width: 70px;
        height: 70px;
    }

    .declarationTitle>p {
        font-size: medium;
    }

    .footerPara {
        flex-direction: column;
        
    }
    .bank-details{
        margin-left: 100px;
    }
  
    .footerParaWrapper{
        flex-direction: column;
    }
    .fieldOfExpertise{
        display: flex;
        flex-direction: column;
       
    }
    .expertiseSec{
        margin-top: 20px;
    }
}


@media only screen and (min-width: 768px) {

    /* Desktop styles go here */
    .formWrapper {
        width: 70%;

    }

    .bhtform {
        padding: 40px;

    }

    .header {
        height: 150px;
    }
}
