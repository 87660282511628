* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

a {
  text-decoration: none;
}

.dropdown-item {
  width: 100%;
  background-color: #fff !important;
  display: block;
}

.dropdown-menu.show {
  border: 1px solid black;
  overflow: auto;
}

.dropdown-menu:hover {
  color: rgb(143, 30, 30);
}

.signature-pad {
  width: 500px;
  height: 300px;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .signature-pad {
    width: 100%;
    height: 200px;
  }
}

/* Media query for larger screens (web view) */
@media (min-width: 768px) {
  .signature-pad {
    width: 100%;
    height: 200px;
  }
}
